.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* SearchComponent.css */


.search-container {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-input {
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  outline: none; /* Remove the default input outline */
}

.search-button {
  padding: 10px 20px;
  background-color: #4caf50; /* Green color, adjust as needed */
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  outline: none; /* Remove the default button outline */
}

/* Hover effect for the button */
.search-button:hover {
  background-color: #45a049; /* Darker green on hover, adjust as needed */
}

.card {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  margin: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}
.card.green {
  background-color: #aafca3; /* Adjust the color as needed */
}

/* Red background */
.card.red {
  background-color: #fcaaa3; /* Adjust the color as needed */
}

.card:hover {
  transform: translate(5px, 5px);
}

.left-section {
  flex: 1;
  display: flex;
  align-items: center;
}

.middle-section {
  flex: 2;
  display: normal;
  align-items: center;
}

.right-section {
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative; /* Position relative for absolute positioning of the circle */
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 8px;
}

p {
  font-size: 1rem;
  color: #333;
}


.graph-line {
  position: absolute;
  top: 50%;
  left: 0%;
  right: 80%;
  transform: translate(-50%);
  width: 80%; /* Adjust the length of the line as needed */
  height: 2px;
  margin-right: auto;
  background-color: #ccc;
}

.graph-circle {
  position: absolute;
  top: 50%;
  left: 50%; /* Position the circle at the end of the line */
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  background-color: #614caf; /* Green color, adjust as needed */
  border-radius: 50%;
  z-index: 1;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

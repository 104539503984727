.coming-soon-container {
    display: flex;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    background-color: transparent;
  }
  
  .content-wrapper {
    text-align: center;
    padding: 50px;
    max-width: 600px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  .content-wrapper h1 {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .content-wrapper p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 30px;
  }
  
  .email-form {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .email-form input {
    padding: 10px 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    flex: 1;
  }
  
  .email-form button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .social-media-links {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .social-media-links a {
    margin: 0 10px;
    font-size: 20px;
    color: #ccc;
  }
  
  .social-media-links a:hover {
    color: #007bff;
  }
  
  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("app-icon.svg");
    background-size: cover;
    background-position: center;
    opacity: 0.3;
    z-index: -1;
  }
  